<template>
  <div class="wrap">
    <header class="x-page-title">
      <h3>购买订单信息</h3>
    </header>

    <main class="x-main">
      <el-button
        icon="el-icon-arrow-left"
        type="primary"
        size="small"
        @click="$router.back()"
        >返回</el-button
      >
      <!-- 标题类型 -->
      <el-card class="mt25">
        <div slot="header">
          <span>基本信息：</span>
        </div>
        <div class="x-mian-content">
          <el-row type="flex" justify="space-around">
            <el-col :span="6"
              ><div class="orderInfo">
                订单号：{{ orderInfo.orderId }}
              </div></el-col
            >
            <el-col :span="6"
              ><div class="orderInfo">
                支付单号：{{ orderInfo.payOrderId || "暂无信息" }}
              </div></el-col
            >
            <el-col :span="6"
              ><div class="orderInfo">
                下单时间：{{ orderInfo.createdAt }}
              </div></el-col
            >
          </el-row>
          <el-row type="flex" justify="space-around">
            <el-col :span="6"
              ><div class="mt10 orderInfo">
                买家用户昵称：{{ orderInfo.nickName }}
              </div></el-col
            >
            <el-col :span="6"
              ><div class="mt10 orderInfo">
                买家手机号：{{ orderInfo.phoneNumber }}
              </div></el-col
            >
            <el-col :span="6"
              ><div class="mt10 orderInfo">
                付款时间：{{ orderInfo.payAt || "暂无信息" }}
              </div></el-col
            >
          </el-row>
          <el-row type="flex" justify="space-around">
            <el-col :span="6"
              ><div class="mt10 orderInfo">
                订单金额：{{ orderInfo.actualPay }}
              </div></el-col
            >
            <el-col :span="6"
              ><div class="mt10 orderInfo">
                付款方式：{{ orderInfo.payType }}
              </div></el-col
            >
            <el-col :span="6"></el-col>
          </el-row>
        </div>
      </el-card>

      <el-card class="mt25">
        <div slot="header">
          <span>商品信息：</span>
        </div>
        <div>
          <el-table :data="goodsInfo" stripe style="width: 100%">
            <el-table-column prop="NFTID" label="藏品id"> </el-table-column>
            <el-table-column prop="NFTName" label="藏品名称"> </el-table-column>
            <el-table-column prop="brandName" label="品牌名称">
            </el-table-column>
            <el-table-column prop="count" label="数量"> </el-table-column>
            <el-table-column prop="subtotal" label="小计"> </el-table-column>
          </el-table>

          <el-row type="flex" justify="end">
            <el-col :span="4"
              ><div class="mt10 goodsInfo">
                小计：{{ goodsInfo[0].total }}
              </div></el-col
            >
            <el-col :span="4"
              ><div class="mt10 goodsInfo">
                实收金额：{{ goodsInfo[0].realMoney }} (元)
              </div></el-col
            >
          </el-row>
        </div>
      </el-card>
    </main>
  </div>
</template>

<script>
import { OrderDetail } from "@/api/module/order";

export default {
  name: "OrderDetail",
  props: ["id"],
  data() {
    return {
      orderInfo: {},
      goodsInfo: [],
    };
  },
  created() {
    this.getOrderInfo();
  },
  methods: {
    async getOrderInfo() {
      const r = await OrderDetail(`?orderId=${this.id}`);
      console.log(r);
      this.orderInfo = r.orderInfo;
      this.goodsInfo = [r.goodsInfo];
    },
  },
};
</script>
<style lang="less" scoped>
.x-mian-content {
  padding: 20px;
  .orderInfo {
    font-size: 16px;
  }
}
.goodsInfo {
  font-size: 16px;
}
</style>